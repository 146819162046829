import React, { useState, useEffect, useContext } from "react";
import './Admindashboard.css'; // Aquí puedes agregar los estilos
import logo from './assets/Logo.png';
import { useMyState } from "./MyContext";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Bar } from 'react-chartjs-2';  // Importamos el gráfico de barras de react-chartjs-2
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
// Registramos los elementos de Chart.js que vamos a usar
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserVerificador = () =>{
    const isAuthenticated = localStorage.getItem('token');
    const userRole = localStorage.getItem('role');
    const history = useHistory();
  
    if(!isAuthenticated){
      return history.push('/login');
    }
    if(!userRole){
      return history.push('/login');
    }
    if(userRole === 'contador'){
      return history.push('/contador');
    }
    if(userRole === 'admin'){
      return history.push('/admin');
    }
    return User();
}
const User = () =>{
    const {myState3, setMyState3, myUser} = useMyState();
    const history = useHistory();

    const handleLogout = () => {
      // Eliminamos el token y el rol de localStorage al hacer logout
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      history.push('/login');
    };
    const handleEmpresa = () =>{
      setMyState3('MostrarEmpresasUsuarios')
    }
    
      return (
        <div className="dashboard">
            {console.log(myUser)}
          <div className="menu columna">
            <img src={logo}/>
            <div className="menu-list columna">
                
                <a className="selected" href="" onClick={() =>{handleEmpresa()}}><i class="fa-regular fa-building"></i><span> Empresas</span></a>
                <br/>
                <a className="selected" href="" onClick={() =>{handleLogout()}}><i class="fa-solid fa-power-off"></i><span> Cerrar sesión</span></a>
                
            </div>
          </div>
          <div className="panel">
            <div>
            {myState3 === 'MostrarEmpresasUsuarios' && <MostrarEmpresasUsuarios/>}
            {myState3 === 'UsuarioInfo' && <UsuarioInfo/>}
            </div>
          </div>
        </div>
      )
}
const MostrarEmpresasUsuarios = () =>{
  const {misEmpresas, setMisEmpresas, myUser} = useMyState();;

  return (
    <div>
      <div className="user-title">
        <h1>Empresas de {myUser}</h1>
      </div>
      {misEmpresas === 'EmpresasUsuarios' && <EmpresasUsuarios />}
    </div>
  );
}
const EmpresasUsuarios = () =>{
  const [empresas, setEmpresas] = useState([]);
  const {setMyState3, setMyIdEmpresa, setMyName, myIdUser} = useMyState();


  useEffect(() => {
    // Hacer la solicitud HTTP al archivo PHP
    fetch(`https://contapro.com.mx/php/getempresas.php?id=${myIdUser}`)
      .then(response => response.json())
      .then(data => setEmpresas(data))
      .catch(error => console.error("Error fetching users:", error));
  }, []);

  const handleUpdateClick = (id, name) => {
    setMyState3('UsuarioInfo');
    setMyIdEmpresa(id);
    setMyName(name);
  };

  const MensajeEmpresas =  () => {
    return(
     <div>
      <div className="empresas-div">
        {empresas.map((empresa, index) => (
            <div className="empresa-card" key={index} onClick={() => handleUpdateClick(empresa.id, empresa.name )}>
              <i class="fa-regular fa-building"></i>
              <div>
                <h3>{empresa.name}</h3>
                <p>{empresa.rfc}</p>
              </div>
            </div>
        ))}
      </div>
     </div>
    )
  }

  const NoEmpresas = () =>{
    return(
      <h1>No hay empresas</h1>
    )
  }

  return (
    <div>
      {
        (empresas.length === 0 ? NoEmpresas() : MensajeEmpresas()) 
      }
    </div>
  );
}
const UsuarioInfo = () =>{
    const {myIdUser, myName } = useMyState();
    const [myOpcion, setMyOpcion] = useState('contabilidad');
  
    return(
    <div>
      <h1 className="usuario-">Usuario {myName}</h1>
      <div className="pc">
        <div className="menuUsuario">
        <a href="#" onClick={() => setMyOpcion('informacion')}>Información</a>
        <a href="#" onClick={() => setMyOpcion('contabilidad')}>Contabilidad</a>
        <a href="#" onClick={() => setMyOpcion('historial')}>Historial</a>
        <a href="#" onClick={() => setMyOpcion('pagos')}>Pagos</a>
        </div>
      </div>
      <div className="mv">
        <div className="menuUsuario-mv">
          <a href="#" onClick={() => setMyOpcion('informacion')}><i class="fa-solid fa-circle-user"></i></a>
          <a href="#" onClick={() => setMyOpcion('contabilidad')}><i class="fa-solid fa-receipt"></i></a>
          <a href="#" onClick={() => setMyOpcion('historial')}><i class="fa-solid fa-chart-line"></i></a>
          <a href="#" onClick={() => setMyOpcion('pagos')}><i class="fa-solid fa-file-invoice-dollar"></i></a>
        </div>
      </div>
      {myOpcion === 'informacion' && <Informacion />}
      {myOpcion === 'contabilidad' && <Contabilidad />}
      {myOpcion === 'historial' && <Historial id={myIdUser}/>}
      {myOpcion === 'pagos' && <Pagos id={myIdUser}/>}
    </div>
    );
}
//Pantallas para editar info del usuario seleccionado
const Informacion = () =>{
  const {estado, myIdEmpresa} = useMyState();
  const [nombre, setNombre] = useState('');
  const [rfc, setRfc] = useState('');
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');
  const [dfiscal, setDfiscal] = useState('');
  const id = myIdEmpresa;
  const [loading, setLoading] = useState(true); 
    
//obtener datos para mostrar antes de editar

  const [usuario, setUsuario] = useState([]);
  useEffect(() => {
    // Hacer la solicitud HTTP al archivo PHP
    fetch(`https://contapro.com.mx/php/getinfoempresa.php?id=${id}`)
      .then(response => response.json())
      .then(data => {
        setUsuario(data)
        setLoading(false)
      })
      .catch(error => console.error("Error fetching users:", error));
  }, []);
  
  //actualizar datos del usuario
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = { id, nombre, rfc, tel, email, dfiscal };

        try {
            const response = await fetch('https://contapro.com.mx/php/updateuser.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams(data),
            });

            const result = await response.text();
            toast(result);  // Muestra el resultado del backend
        } catch (error) {
            alert('Error al actualizar: ' + error);
        }
        
    };
    if (loading) {
      return <div>Loading...</div>; // Mostrar un mensaje de carga o un spinner mientras cargamos
    }
    return (
        <div className="informacion">
          <h2 className="mv">Información</h2>
            <form onSubmit={handleSubmit}>
            {usuario.map((item, index) =>(
              <div key={index}>
                <div>
                    <label>Nombre: </label>
                    <input 
                        type="text" 
                        
                        placeholder={item.name}
                        onChange={(e) => setNombre(e.target.value)} 
                    />
                </div>
                <div>
                    <label>RFC: </label>
                    <input 
                        type="text" 
                        value={rfc} 
                        placeholder={item.rfc}
                        onChange={(e) => setRfc(e.target.value)} 
                    />
                </div>
                <div>
                    <label>Teléfono: </label>
                    <input 
                        type="tel" 
                        value={tel} 
                        placeholder={item.tel}
                        onChange={(e) => setTel(e.target.value)} 
                    />
                </div>
                <div>
                    <label>Email: </label>
                    <input 
                        type="email" 
                        value={email} 
                        placeholder={item.mail}
                        onChange={(e) => setEmail(e.target.value)} 
                    />
                </div>
                <div>
                    <label>Direccón fiscal: </label>
                    <input 
                        type="text" 
                        value={dfiscal} 
                        placeholder={item.dfiscal}
                        onChange={(e) => setDfiscal(e.target.value)} 
                    />
                </div>
                <br></br>
                <button type="submit"><i class="fa-solid fa-floppy-disk"></i> Actualizar</button>
              </div>
              ))}
              
            </form>
            <div className="info-">
              <div className="recuadro">
                <h3>Estado de aplicción</h3>
                <p>{estado} (último pago 25 de oct 2024)</p>
                <h3>Estado de aplicción</h3>
                <p>10 de oct 2024 10:00 AM</p>
              </div>
            </div>
            <ToastContainer />
        </div>
    );
}
const Contabilidad = () =>{
  const {myIdEmpresa } = useMyState();
  const [mes, setMes] = useState('');
  const [ingresos, setIngresos] = useState('');
  const [gastos, setGastos] = useState('');
  const [iva, setIva] = useState('');
  const [isr, setIsr] = useState('');
  const [rpagar, setRpagar] = useState('');
  const id = myIdEmpresa;
  const [update, setUpdate] = useState(true);
  //const notify = () => toast("¡Notificación!");
    
//obtener datos para mostrar antes de editar
  const [mes1, setMes1 ]= useState('');
  const [ingresos1, setIngresos1] = useState('');
  const [gastos1, setGastos1] = useState('');
  const [iva1, setIva1] = useState('');
  const [isr1, setIsr1] = useState('');
  const [rpagar1, setRpagar1] = useState('');
  const [usuario, setUsuario] = useState([]);
  useEffect(() => {
    // Hacer la solicitud HTTP al archivo PHP
    if(update){
      fetch(`https://contapro.com.mx/php/getinfoempresa.php?id=${id}`)
      .then(response => response.json())
      .then(data => {
        // Asumiendo que 'data' es un array y tomas el primer elemento
        if (data && data.length > 0) {
          setUsuario(data); // Guardas los datos completos si es necesario
          
          // Asignar directamente los valores del primer elemento
          const item = data[0]; // Primer elemento
          setMes1(item.mes);
          setIngresos1(item.ingresos);
          setGastos1(item.gastos);
          setIva1(item.iva);
          setIsr1(item.isr);
          setRpagar1(item.rpagar);
          
        }
      })

      .catch(error => console.error("Error fetching users:", error));
      
    }
  }, [update]);
    return (
        <div className="informacion">
          <h2 className="mv">Contabilidad</h2>
            <form>
            {usuario.map((item, index) =>(
              <div key={index}>
                <div>
                    <label>Mes: </label>
                    <input 
                        type="text" 
                        value={mes}
                        placeholder={item.mes}
                        onChange={(e) => setMes(e.target.value)} 
                        disabled= "false"
                    />
                </div>
                <div>
                    <label>Ingresos: </label>
                    <input 
                        type="text" 
                        value={ingresos} 
                        placeholder={item.ingresos}
                        onChange={(e) => setIngresos(e.target.value)} 
                        disabled= "false"
                    />
                </div>
                <div>
                    <label>Gastos: </label>
                    <input 
                        type="text" 
                        value={gastos} 
                        placeholder={item.gastos}
                        onChange={(e) => setGastos(e.target.value)} 
                        disabled= "false"
                    />
                </div>
                <div>
                    <label>IVA: </label>
                    <input 
                        type="text" 
                        value={iva} 
                        placeholder={item.iva}
                        onChange={(e) => setIva(e.target.value)} 
                        disabled= "false"
                    />
                </div>
                <div>
                    <label>ISR: </label>
                    <input 
                        type="text" 
                        value={isr} 
                        placeholder={item.isr}
                        onChange={(e) => setIsr(e.target.value)} 
                        disabled= "false"
                    />
                </div>
                <div>
                    <label>Retenciones por pagar: </label>
                    <input 
                        type="text" 
                        value={rpagar} 
                        placeholder={item.rpagar}
                        onChange={(e) => setRpagar(e.target.value)} 
                        disabled= "false"
                    />
                </div>
                <br></br>
                </div>
              ))}
            </form>
            <div className="info-">
              <div className="recuadro">
                <h3>Constancia Fiscal</h3>
                <p>{ViewPDF()}</p>
                <h3>Impuestos por pagar</h3>
                <p>{ViewPDF2()}</p>
              </div>
            </div>
            <ToastContainer />
        </div>
    );
}
const ViewPDF = () => {
  const {myIdEmpresa} = useMyState();
  const [url, setUrl] = useState();

  const downloadUrl = `https://contapro.com.mx/php/get_pdf.php?id=${myIdEmpresa}`;

 /* const handleDownload = () => {
    // Generar la URL de descarga
    const downloadUrl = `https://contapro.com.mx/php/get_pdf.php?id=${myIdUser}`;
    setUrl(downloadUrl);
    alert(downloadUrl);
    // Crear un enlace y forzar la descarga
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `archivo_${myIdUser}.pdf`; // Puedes usar cualquier nombre para el archivo
    link.click();
  };*/

  return (
    <div>
      <a href={downloadUrl} target="_blank">Constancia Fiscal</a>
    </div>
  );
}
const ViewPDF2 = () => {
  const {myIdEmpresa} = useMyState();
  //const downloadUrl = '';


  const downloadUrl = `https://contapro.com.mx/php/get_pdf_impuestos.php?id=${myIdEmpresa}`;


  /*const handleDownload = () => {
    // Generar la URL de descarga
    const downloadUrl = `https://contapro.com.mx/php/get_pdf_impuestos.php?id=${myIdUser}`;
    // Crear un enlace y forzar la descarga
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `archivo_${myIdUser}.pdf`; // Puedes usar cualquier nombre para el archivo
    link.click();
  };*/

  return (
    <div>
      <a href={downloadUrl} target="_blank">Impuestos por pagar</a> 
    </div>
  );
}
const Historial = () =>{
  // Variables predefinidas con los datos
  const meses1 = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo'];  // Meses
  const [meses, setMeses] = useState([]);
  const ingresos1 = [30, 45, 60, 70, 50];  // Ventas correspondientes a cada mes
  const [ingresos, setIngresos] = useState([]);
  const gastos2 = [30, 45, 60, 70, 50];  // Ventas correspondientes a cada mes
  const [gastos, setGastos] = useState([]);




  // Usamos las variables para crear los datos para la gráfica
  const data = {
    labels: meses,  // Usamos la variable meses como etiquetas
    datasets: [
      {
        label: 'Ingresos',  // Título de la serie
        data: ingresos,  // Usamos la variable ventas como los datos
        backgroundColor: 'rgba(75, 192, 83, 0.2)',  // Color de las barras
        borderColor: 'rgba(75, 192, 192, 1)',  // Color del borde de las barras
        borderWidth: 1,
      },
      {
        label: 'Gastos',  // Título de la serie
        data: gastos,  // Usamos la variable ventas como los datos
        backgroundColor: 'rgba(75, 186, 192, 0.2)',  // Color de las barras
        borderColor: 'rgba(75, 192, 192, 1)',  // Color del borde de las barras
        borderWidth: 1,
      },
    ],
  };

  // Opciones para personalizar la gráfica
  const options = {
    responsive: true,  // Hacer que la gráfica sea responsiva
    plugins: {
      title: {
        display: true,
        text: '(OCT-2023 -- DEC 2024)',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,  // El eje Y comienza en cero
      },
    },
    animation: {
      duration: 1500, // Duración de la animación en milisegundos
      easing: 'easeOutBounce', // Tipo de animación (puedes probar otros como 'easeInOutQuad', 'linear', etc.)
      onProgress: function(animation) {
        //console.log('Animación en progreso: ' + animation.currentStep);
      },
      onComplete: function() {
        //console.log('Animación completada');
      }
    }
  };


  //obtener los historiales
  const [historial, setHistorial] = useState([]);
  const {myIdEmpresa} = useMyState();
  const [myHistorial, setMyHistorial] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    // Hacer la solicitud HTTP al archivo PHP
    fetch(`https://contapro.com.mx/php/gethistorial.php?id=${myIdEmpresa}`)
      .then(response => response.json())
      .then(data => {
        data && setHistorial(data)
        setLoading(false)
      })
      .catch(error => console.error("Error fetching users:", error))
      .finally(() => datosGrafica())
  }, []);

const datosGrafica = () =>{
  setIsLoading(false)
}

//cargamos los datos obtenidos en los arreglos
useEffect( () =>{
  const mes =  !isLoading && historial.map(item => item.mes);
  setMeses(mes)
  const ing =  !isLoading && historial.map(item => item.ingresos);
  setIngresos(ing)
  const gas =  !isLoading && historial.map(item => item.gastos);
  setGastos(gas)
}, [isLoading]);

  const mostrarInfomes = () =>{
    return(
      <div className="recuadro">
                <div>
                  <h3>Ingresos</h3>
                  {!isLoading && <p>${historial[myHistorial].ingresos}</p>}
                </div>
                <div>
                  <h3>Gastos</h3>
                  {!isLoading && <p>${historial[myHistorial].gastos}</p>}
                </div>
                <div>
                  <h3>IVA</h3>
                  {!isLoading && <p>${historial[myHistorial].iva}</p>}
                </div>
                <div>
                  <h3>ISR</h3>
                  {!isLoading && <p>${historial[myHistorial].isr}</p>}
                </div>
                <div>
                  <h3>Retenciones</h3>
                  {!isLoading && <p>${historial[myHistorial].rpagar}</p>}
                </div>
        </div>
    )
  }

  const mostrarMeses = () =>{
    return(
      <>
        {historial !== 'vacio' && historial.map((his, index) =>(
            <div className="mes-item" key={index} onClick={() => mostrarInfo(index)}>
            <i class="fa-regular fa-file-lines"></i>
              <div>
                <p>${his.ingresos}</p>
                <h3>{his.mes}</h3>
              </div>
            </div>
          ))}
      </>
    )
  }

  const mostrarMensaje = () =>{
    alert('No hay historial')
  }

  const mostrarInfo = (id) =>{
    setMyHistorial(id);
    console.log(id);
    //mostrarInfomes(id);
  }
  if (loading) {
    return <div>Loading...</div>; // Mostrar un mensaje de carga o un spinner mientras cargamos
  }
  return (
    <div className="historial">
      <h2 className="mv">Historial</h2>
      <div className="grafica">
        <h2>Tnedencia anual</h2>
        <div className="grafica-tabla">
          <Bar data={data} options={options} />  {/* Renderizamos la gráfica */}
        </div>
      </div>
      <div className="panel-info">
        <div className="meses">
          <h2>Meses</h2>
          {(historial === 'vacio' ? mostrarMensaje() : mostrarMeses())}
        </div>
        {(historial === 'vacio' ? mostrarMensaje() : mostrarInfomes())}
      </div>
    </div>
  )
}
const Pagos = () =>{
  return <h2>Pendiente</h2>
}
//
export default UserVerificador