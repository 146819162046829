import React from 'react';
import './App.css';
import LoginVerificador from './Login';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import ContadorVerificador from './ContadorDashboard';
import UserDashboard from './UserDashboard';
import {MyProvider} from './MyContext';


function App() {

  return (
    <MyProvider>
    <div className="App">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>
      <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet"/>
      <Router>
            <Switch>
                <Route path="/login" component={LoginVerificador} />
                <Route path="/admin" component={AdminDashboard} />
                <Route path="/contador" component={ContadorVerificador} />
                <Route path="/dashboard" component={UserDashboard} />
                <Route path="/" exact component={LoginVerificador} />
                <Redirect from="*" to="/login" />
            </Switch>
        </Router>
    </div>
    </MyProvider>
  );
}

export default App;